:root {
  --col1: #EB5400;
  --col2: #252538;
  --col3: #f8f9fa;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, padding 0.5s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 3vw;
  letter-spacing: 0.1vw;
}

.headerTransparent {
  background-color: #252538
}
.linksContainer a.activeLink {
  color: #EB5400; /* Couleur pour la section active */
}
.headerDark {
  background-color: #252538;
  padding: 1vw 2vw;
}

.logoContainer {
  order: 1;
}

.logoContainer img {
  max-height: 8.1vh;
  padding-bottom:1vh;
  padding-top:1vh;
  vertical-align: middle;
}

.linksContainer {
  order: 2;
  display: flex;
  gap: 5vw;
}

.linksContainer a {
  color: var(--col3);;
  text-decoration: none;
  font-size: 1.1em;
  font-family: 'GR', sans-serif;
  position: relative;
  transition: color 0.3s ease;
}

.linksContainer a:after {
  content: "";
  position: absolute;
  bottom: -0.2vh;
  left: 0;
  width: 0;
  height: 0.4vh;
  border-radius:0.1vh;
  background-color: var(--col1);
  transition: width 0.3s ease;
}
.linksContainer a.activeLink:hover:after {
  width: 0; /* Désactive le soulignement pour les liens actifs */
}
.linksContainer a:hover:after {
  width: 100%;
}


.contactButton {
  order: 3;
  background-color: var(--col3);
  color: #EB5400;
  font-family: 'GS', sans-serif;
  padding: 1.3vh 2.1vh;
  font-size: 1em;
  height: 100%;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 25px;
  transition: border-radius 0.5s ease, box-shadow 0.3s ease;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.05vw;
}

.contactButton img {
  width: 20px;
  margin-right: 10px;
}

.contactButton:hover {
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}



  /* Smartphones (portrait) ----------- */
  @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 480px)
    and (orientation : portrait) {
  .header{
    padding: 1vh 3vw;
  }
  .logoContainer img {
      vertical-align: middle;
      max-height: 7vh;;
  }
  .linksContainer {
      display: none;
  }
  .contactButton {
    padding: 1vh 2vh;
    font-size:0.8em;
    border-radius: 18px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-right:2vw
  }
  .contactButton:hover {
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .contactButton img {
      width: 14px;
      margin-right: 10px;
  }
}



  /* Smartphones (landscape) ----------- */
  @media only screen 
  and (min-width : 321px)
  and (max-device-width : 480px)
  and (orientation : landscape) {
    .logoContainer img {
      max-height: 10vh;
      padding-bottom:1vh;
      padding-top:1vh;
      vertical-align: middle;
    }

    .linksContainer a {
      color: var(--col3);;
      text-decoration: none;
      font-size: 1em;
      font-family: 'GR', sans-serif;
      position: relative;
      transition: color 0.3s ease;
    }

    .contactButton {
      padding: 2vh 3.5vh;
      font-size: 0.9em;
      border-radius: 20px;
      transition: border-radius 0.5s ease, box-shadow 0.3s ease;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      text-decoration: none;
    }

    .contactButton:hover {
      border-top-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
