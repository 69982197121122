:root {
    --col1: #EB5400;
    --col2: #322C5A;
  }
@font-face {
    font-family: 'GB';
    src: url('./../../../public/Gilroy-Bold.woff') format('woff'); 

}

@font-face {
    font-family: 'GM';
    src: url('./../../../public/Gilroy-Medium.woff') format('woff'); 
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GR';
    src: url('./../../../public/Gilroy-Regular.woff') format('woff'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'GS';
    src: url('./../../../public/Gilroy-SemiBold.ttf') format('truetype'); 
    font-weight: normal;
    font-style: normal;
}
.body{
  background-color: #f8f9fa;
}

.majsite{
    width:100%;
    height:50vh;
    display:block;
}

.majsite{
    font-size:1em;
    font-family : "GM", sans-serif;
    text-align:center;
    color:var(--col2);
    margin-top:25vh
}