:root {
  --col1: #EB5400;
  --col2: #252538;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
}

.footer {
  background-color: white;
  color: var(--col2);
  padding: 15px;
  padding-bottom:0px;
  box-sizing: border-box;
  width: 100%;
  border-top: 0.1px solid #808080;
}

.Backtop {
  text-align: right;
  cursor: pointer;
  flex: 1;
  font-family: "GS";
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center; /* Add this line */
}

.Backtop > span { 
  margin-left: auto;  
}

.iconBackTop{
  padding-left: 8px;
}

.Backtop span {
  position: relative;
  transition: color 0.3s ease;
}

.Backtop span:after{
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  border-radius: 1px;
  background-color: var(--col1);
  transition: width 0.3s ease;
}

.Backtop span:hover:after{
  width: 100%;
}

.Infos {
  flex: 1;
  font-family: "GM";
  font-size: 1.2em;
  text-align: center;
}
.Infos > span { 
  margin-right: auto; 
}

.contactButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%; /* Ajustez la valeur selon vos besoins */
}

.contactButton {
  background-color: var(--col1);
  color: white;
  font-family: 'GM', sans-serif;
  padding: 11px 20px;
  font-size: 1.1em;
  height: 100%;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 25px;
  border-top-left-radius:0px;
  border-bottom-right-radius:0px;
  transition: border-radius 0.5s ease, box-shadow 0.3s ease;
}

.contactButton:hover {
  border-top-left-radius:25px;
  border-bottom-right-radius:25px;
  border-top-right-radius:0px;
  border-bottom-left-radius:0px;
}

.Icons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1%; /* Ajustez la valeur selon vos besoins */
  margin-top:2%;
}

.icon{
  color:var(--col2);
  font-size: 2.5em;
  transition: transform 0.3s ease;

}
.icon:hover{
  cursor:pointer;
  transform: scale(1.2);
  color:#EB5400
}

.Copyright {
  flex: 1;
  font-family: "GR";
  font-size: 1em;
  margin-top:2em;
  display: flex;
  align-items: center; /* Pour centrer verticalement le contenu */
  justify-content: center; /* Pour centrer horizontalement le texte */
}

.Copyright p {
  margin: 0; /* Supprimez la marge par défaut du paragraphe */
}

.Copyright > span { 
  margin-right: auto; 
}

.footerImg {
  position: relative;
}

.cnjeImage,
.partnersImage {
  position: absolute;
  height: 13vh; /* Remplacez 100px par la hauteur souhaitée */
  bottom: 0;
  margin-bottom:1%;
  cursor:pointer
}

.cnjeImage {
  left: 0;
  z-index: 1;
  margin-left:1%;
}

.partnersImage {
  right: 0;
  z-index: 1;
  margin-right:1%;
}

/* Assurez-vous que les images ne bougent pas et restent fixes */
.cnjeImage img,
.partnersImage img {
  height: 100%;
  object-fit: cover; /* Pour maintenir les proportions et remplir complètement le conteneur */
}



  /* Smartphones (portrait) ----------- */

  @media only screen 
  and (min-device-width : 320px) 
  and (max-device-width : 480px)
  and (orientation : portrait) {
  body{
    min-height: 10em;
  }
  .footer {
    font-size: 13px;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
    width: 100%;
    border-top: 0.1px solid #808080;
  }
  .Infos{
    font-size:1.3em;
  }
  
  .Backtop {
    text-align: right;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .contactButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contactButtonContainer div {
    margin-bottom: 2vh;
    width: 60%;
  }
  
  .contactButton {
    width: 100%; 
    font-size: 1.1em;
  }

  .contactButton {
    border-radius: 20px;
    border-top-left-radius:0px;
    border-bottom-right-radius:0px;
    padding: 11px 11px;
  }

  .contactButton:hover {
    border-top-left-radius:20px;
    border-bottom-right-radius:20px;
    border-top-right-radius:0px;
    border-bottom-left-radius:0px;
  }

  .cnjeImage,
  .partnersImage {
    position: absolute;
    height: 7vh; /* Remplacez 100px par la hauteur souhaitée */
    bottom: 0;
    margin-bottom:1%;
    cursor:pointer
  }
}






  /* Smartphones (landscape) ----------- */
  @media only screen 
  and (min-width : 321px) 
  and (orientation : landscape) {
    .contactButtonContainer {
      display: flex;
      align-items: center;
      margin-left: 5%;
      margin-right:5%;
    }

    .contactButton {
      background-color: var(--col1);
      color: white;
      font-family: 'GM', sans-serif;
      padding: 11px 20px;
      font-size: 1em;
      height: 100%;
      cursor: pointer;
      white-space: nowrap;
      vertical-align: middle;
      border: 1px solid transparent;
      border-radius: 25px;
      border-top-left-radius:0px;
      border-bottom-right-radius:0px;
      transition: border-radius 0.5s ease, box-shadow 0.3s ease;
    }
    
  }
