
:root {
    --col1: #EB5400;
    --col2: #322C5A;
}

@font-face {
    font-family: 'GB';
    src: url('./../../../public/Gilroy-Bold.woff') format('woff');
}

@font-face {
    font-family: 'GM';
    src: url('./../../../public/Gilroy-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GR';
    src: url('./../../../public/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GS';
    src: url('./../../../public/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.body {
    background-color: #f8f9fa;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 75vh;
}

.quesContainer{
    margin-top: 10%;
}
.quesContainer h1 {
    font-family: 'GS', sans-serif;
    text-align: center;
    font-size: 2.8em;
    color: var(--col2);
    text-decoration: underline !important;
    text-decoration-color: #EB5400 !important;
    text-underline-offset: 15px;
}
.quesContainer h2 {
    font-family: 'GM', sans-serif;
    font-size: 2em;
    color: var(--col2);
    margin-left:8vw;

}
.quesContainer p {
    font-family: 'GR', sans-serif;
    font-size: 1.3em;
    color: var(--col2);
    margin-left:2vw;
    margin-right:2vw

}

