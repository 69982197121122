
:root {
    --col1: #EB5400;
    --col2: #322C5A;
}

@font-face {
    font-family: 'GB';
    src: url('./../../../public/Gilroy-Bold.woff') format('woff');
}

@font-face {
    font-family: 'GM';
    src: url('./../../../public/Gilroy-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GR';
    src: url('./../../../public/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GS';
    src: url('./../../../public/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.body {
    background-color: #f8f9fa;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 75vh;
}

.m_3f35ae96 .mantine-Accordion-chevron {
    width: 10vh;
    height: 10vh;
}

.quesContainer {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Disposition en colonne */
    gap: 40px; /* Ajouter un espace entre les boîtes */
    margin-bottom: 1em; /* Espace en bas */
}

.quesContainer h1 {
    font-family: 'GS', sans-serif;
    text-align: center;
    font-size: 2.8em;
    color: var(--col2);
    text-decoration: underline !important;
    text-decoration-color: #EB5400 !important;
    text-underline-offset: 15px;
}

.quesContainer span {
    color: var(--col1);
    font-family: 'GM', sans-serif;
}

.accordion {
    display: flex;
    flex-direction: column;
    align-items: center; /* Alignement vertical */
    width: 100%;
}

.Question {
    width: 75vw;
    margin-bottom: 20px; /* Ajouter un espace entre chaque boîte */
    cursor: pointer;
    height: auto;
    background-color: white;
    border-radius: 17px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
    animation: borderAnimation 3s linear infinite;
}

.Question h2 {
    font-family: "GM", sans-serif;
    color: var(--col2);
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
}

.Question h3 {
    margin-top: 0;
    font-family: "GR", sans-serif;
    color: var(--col2);
    margin-left: 5%;
    margin-right: 5%;
    text-align: justify;
}

@keyframes borderAnimation {
    0% {
        border-image-source: linear-gradient(to right, transparent, var(--col1), transparent);
        border-image-slice: 1;
    }
    100% {
        border-image-source: linear-gradient(to right, transparent, var(--col1), transparent);
        border-image-slice: 2;
    }
}

.Question:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-color: var(--col1); /* Couleur de la bordure au survol */
}

@media only screen /* Smartphones (landscape) ----------- */
and (min-width: 321px)
and (max-device-width: 480px)
and (orientation: landscape) {
    .quesContainer h1 {
        font-size: 2.5em;
    }

    .Question h2 {
        font-size: 1.4em;
    }

    .Question h3 {
        font-size: 1em;
    }
}
