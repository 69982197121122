:root {
  --col1: #EB5400;
  --col2: #252538;
  --col3: #f8f9fa;
}

@font-face {
  font-family: 'GB';
  src: url('./../../../public/Gilroy-Bold.woff') format('woff');
}

@font-face {
  font-family: 'GM';
  src: url('./../../../public/Gilroy-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GR';
  src: url('./../../../public/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GS';
  src: url('./../../../public/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  text-decoration: none !important;
}

body {
  background-color:var(--col3);
  height:100%
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backgroundImage {
  background-image: url('../../../public/backamje2.webp');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-bottom: 25.05%; /* (img-height / img-width * container-width) (ancien 25.03) */
  padding-top:25.05%; /* (961 / 1920 * 100) puis divisé par 2 (ancien 25.03%*/ 
}

.overlayText {
  position: relative;
  top: 0%;
  transform: translateY(-50%);
  width: 100%; 
}

.Text h1, .Text h2{
  position:relative;
  left:4%
}
.contactButtonContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
}

.contactButton {
  margin-top: 15px;
  background-color: var(--col1);
  color: var(--col3);
  font-family: 'GS', sans-serif;
  padding: 11px 20px;
  font-size: 17px;
  height: 100%;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 25px;
  border-top-left-radius:0px;
  border-bottom-right-radius:0px;
  transition: border-radius 0.5s ease, box-shadow 0.3s ease;
}

.contactButton2 {
  margin-top: 15px;
  border:none;
  color: var(--col1);
  background-color: var(--col3);
  font-family: 'GS', sans-serif;
  padding: 11px 20px;
  font-size: 17px;
  height: 100%;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 25px;
  border-top-right-radius:0px;
  border-bottom-left-radius:0px;
  transition: border-radius 0.5s ease, box-shadow 0.3s ease;
}

.overlayText h1 {
  font-family: 'GM', sans-serif;
  font-size: 70px;
  margin-bottom: 15px;
  color: var(--col3);
  max-width: 50%; 
  letter-spacing: 2px;
}

.overlayText h2 {
  font-family: 'GR', sans-serif;
  font-size: 28px;
  margin-top: 15px;
  color: var(--col3);
  max-width: 50%; 
}

.overlayText .contactButton:hover {
  border-top-left-radius:25px;
  border-bottom-right-radius:25px;
  border-top-right-radius:0px;
  border-bottom-left-radius:0px;
}

.overlayText .contactButton2:hover {

  border-top-right-radius:25px;
  border-bottom-left-radius:25px;
  border-top-left-radius:0px;
  border-bottom-right-radius:0px;
}

.subtitlesContainer {
  position: relative;
  width: 80%; 
  margin: 0 auto;
}

.subtitlesContainer::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, var(--col1), var(--col2),var(--col1));
  background-size: 500%;
  animation: waveAnimation 20s linear infinite;
  z-index: -1;
  border-radius:2px;
}

@keyframes waveAnimation {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 500% 0;
  }
}
  
.subtitles{
  width: 70%;
  text-align: center;
  margin: 0 auto;
  position: relative; 
  background-color: var(--col3);
}

.subtitles h1{
  font-family: "GM", sans-serif;
  color:var(--col2);
  display: block;
  position: relative;
  cursor:pointer;
  margin-bottom: 0;
}

.subtitles h2{
  font-family: "GR", sans-serif;
  color:var(--col2);
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
  letter-spacing: 0.5px;
}

.subtitles h1 span {
  display: inline-block;
  transition: transform 0.3s ease, color 0.3s ease; 
}

.subtitles h2 span {
  position: relative;
  display: inline-block;
}

.subtitles h2:hover{
  cursor:pointer
}

.subtitles h2 span:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  border-radius: 1px;
  background-color: var(--col1);
  transition: width 0.3s ease;
}

.subtitles h2:hover span:after {
  width: 100%;
}

.subtitles h1 span:hover {
  color: var(--col1);
  transform: translateY(-5px); 
}

.Figures {
  margin:30px;
  width:90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.boxFigure h1{
  font-family: "GM", sans-serif;
  color:var(--col2);  
  margin-bottom: 0;
  margin-top:10%;
}
  
.boxFigure h2{
  margin-top:0;
  font-family: "GR", sans-serif;
  color:var(--col2);
  margin-bottom:10%;
}
  
@keyframes borderAnimation {
  0% {
    border-image-source: linear-gradient(to right, transparent, var(--col1), transparent);
    border-image-slice: 1;
  }
  100% {
    border-image-source: linear-gradient(to right, transparent, var(--col1), transparent);
    border-image-slice: 2;
  }
}

.boxFigure {
  cursor:pointer;
  width: 30%;
  height: auto;
  margin: 10px;
  margin-bottom:0;
  text-align: center;
  background-color: white;
  border-radius: 17px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;
  animation: borderAnimation 3s linear infinite;
}

.boxFigure:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-color: var(--col1);
}

.TitlesWeb h1{
  text-align:center;
  font-family:'GM', sans-serif;
  font-size:4em;
  color:var(--col2);
  cursor:pointer;
  position:relative;
  z-index:100;
}

.TitlesWeb h1:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 50%;
  height: 0.5vh;
  border-radius:1px;
  background-color: var(--col1);
  transition: width 0.3s ease;
  left:25%
}

.boxPrestations {
  margin: 30px;
  margin-top:0;
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
  
.wrapperPres {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextPres {
  width: 45%;
}
  
.contactButtonContainer_Text {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
}

.contactButton_Text {
  margin-top: 15px;
  background-color: var(--col1);
  color: var(--col3);
  font-family: 'GS', sans-serif;
  padding: 11px 20px;
  font-size: 17px;
  height: 100%;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 25px;
  border-top-left-radius:0px;
  border-bottom-right-radius:0px;
  transition: border-radius 0.5s ease, box-shadow 0.3s ease;
}

.contactButton_Text:hover {
  border-top-left-radius:25px;
  border-bottom-right-radius:25px;
  border-top-right-radius:0px;
  border-bottom-left-radius:0px;
}

.carouselPres {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 90%;
  padding: 0 12px;
  opacity: 0;
  filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  animation: carousel-animate-vertical 20s linear infinite;
  background-size: contain;
  background-repeat: no-repeat;
}
  
.carousel__item:nth-child(1) {
  animation-delay: calc(4s * -1);
}

.carousel__item:nth-child(2) {
  animation-delay: calc(4s * 0);
}

.carousel__item:nth-child(3) {
  animation-delay: calc(4s * 1);
}

.carousel__item:nth-child(4) {
  animation-delay: calc(4s * 2);
}

.carousel__item:nth-child(5) {
  animation-delay: calc(4s * 3);
}

.carousel__item:last-child {
  animation-delay: calc(-4s * 2);
}


@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%, 20% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  23%, 40% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  43%, 60% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  63% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}
  
.carousel__item_head {
  border-radius: 50%;
  background-color: var(--col2);
  width: 90px;
  height: 90px;
  padding: 10px;
  position: relative;
  margin-right: -55px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.carousel__item_head img{
  max-width:70px;
  max-height: 70px;
}

.carousel__item_body {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 10em 20px 16px 70px;
}

.TitlePres {
  display: flex;
  align-items: center;
  font-size: 1.3em;
  font-family: 'GS', sans-serif;
  color: white;
  background-color: var(--col1);
  border-radius: 15px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 3px 1.1em;
  width: min-content;
}

.TextPres h1{
  font-family: "GS", sans-serif;
  color:var(--col2);  
  font-size:2.7em;
  margin-bottom: 10%;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
  letter-spacing: 0.5px;
}
  
.TextPres h2{
  margin-top:0;
  font-family: "GR", sans-serif;
  color:var(--col2);
  font-size:1.4em;
  text-align: justify;
}

.TextPres h1 span {
  position: relative;
  display: inline-block;
}

.TextPres h1:hover{
  cursor:pointer
}

.TextPres h1 span:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  border-radius: 1px;
  background-color: var(--col1);
  transition: width 0.3s ease;
}

.TextPres h1:hover span:after {
  width: 100%;
}

.demarche {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  overflow-wrap: break-word;
  margin-top:5vh;
}

.demarchebox {
  height: auto;
  width: 23%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin-bottom: 1vh;
  text-align: center;
  background-color: white;
  border-radius: 17px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding-bottom:10vh;
  overflow-wrap: break-word;
  display: block;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: 2px solid transparent;
  animation: borderAnimation 3s linear infinite;
}

.demarchebox h1{
  font-family: "GM", sans-serif;
  color:var(--col2);  
  margin-bottom: 0;
  margin-top:10%;
  font-size:1.5em;
  padding-left:5%;
  padding-right:5%;
  overflow-wrap: break-word;
}

.demarchebox h2{
  font-family: "GR", sans-serif;
  color:var(--col2);  
  margin-bottom: 0;
  margin-top:10%;
  font-size:1em;
  padding-left:5%;
  padding-right:5%;
  overflow-wrap: break-word;
}

.demarcheline{
  background-color: #EB5400;
  width:80%;
  height:0.3em;
  align-items: center;
  justify-content: center;
  margin:auto;
  margin-top:5%;
  margin-bottom:5%;
  border-radius:2px;
}

.demarchehead {
  background-color: var(--col2);  
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:auto;
  margin-bottom: 1vh; 
  margin-top:-5vh;
}

.demarchehead img{
  height:6vh;
}

.demarchebox:hover{
  cursor:pointer;
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.brandsCarousel {
  background-color: white;
  text-align: center;
  margin: 0 auto;
  width: 80%;
  border-radius: 17px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom:10vh
}

.carouselBox {
  padding: 10%;
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9em;
}

.imgbody {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

.imgbody img {
  object-fit: cover;
  width: 90%;
  height: auto;
  filter: grayscale(100%);
  margin: auto;
  left: 0%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease, filter 0.3s ease;
}

.imgbody:hover img {
  filter: grayscale(0%);
  cursor: pointer;
  transform: translate(-50%, -50%) scale(1.1);
}

.OurTeam {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  overflow-wrap: break-word;
}

.Teambox {
  height: auto;
  width: 21vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow-wrap: break-word;
  cursor: pointer;
}

.TeamTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5em; 
  min-height: 3em;
}

.TeamTitle h1 {
  font-family: "GS", sans-serif;
  color: var(--col2);
  margin: 0;
  font-size: 1.5em;
  padding-left: 5%;
  padding-right: 5%;
  overflow-wrap: break-word;
}

.Teambox h1 span {
  color: var(--col1);
}

.TeamPicture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 10px; 
}

.TeamPicture img {
  width: 13vw;
  height: 13vw;
  border-radius: 50%;
  border-top-left-radius: 0;
  transition: border-radius 1s ease;
  border: 0.2vw solid var(--col1);
}

.TeamName {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin-top: 10px; 
}

.TeamPicture img:hover{
  border-top-left-radius:50%;
  border-bottom-right-radius: 0;
}

.TeamName h1 {
  font-family: "GS", sans-serif;
  color: var(--col2);
  margin: 0;
  font-size: 1.5em;
  padding-left: 5%;
  padding-right: 5%;
  overflow-wrap: break-word;
}





@media only screen /* Smartphones (portrait) ----------- */
    and (min-device-width : 320px) 
    and (max-device-width : 480px)
    and (orientation : portrait) {

    .overlayText{
      top: 50%;
      left: 2%;
      right:2%;
    }

    .overlayText h1{
      max-width: 90%;
    }
    .overlayText h2{
      max-width: 90%;;
    }
    .overlayText h1 {
      font-size: 35px;
    }

    .overlayText h2 {
      font-size: 16px;
    }

    .overlayText .contactButton {
      padding: 8px 15px;
      font-size: 14px;
      border-radius:20px;
      border-top-left-radius:0px;
      border-bottom-right-radius:0px;
    }

    .overlayText .contactButton2 {
      padding: 8px 15px;
      font-size: 14px;
      border-radius:20px;
      border-top-left-radius:0px;
      border-bottom-right-radius:0px;
    }

    .backgroundImage {
      background-size: contain;
      background-image: url('../../../public/backamjephone2.webp');
      background-repeat: no-repeat;
      height: 0;
      width:100%;
      padding-bottom: 63.595%; /* (img-height / img-width * container-width) (anciens 63.766)*/
      padding-top:63.595% /* (982 / 770 * 100) */
    }

    .contactButtonContainer{
      flex-direction:column;
    }

    .overlayText .contactButton img {
      width: 18px;
    }

    .overlayText .contactButton2 img {
        width: 18px;
    }

    .subtitlesContainer{
      width:90%
    }

    .subtitles h2{
      font-size:1.2em;
    }

    .Figures{
      flex-direction: column;
    }

    .boxFigure{
      width:80%
    }

    .boxFigure h1{
      font-size:1.9em;
    }

    .boxFigure h2{
      font-size:1.2em;
    }

    .TitlesWeb h1{
      font-size:2.5em;
    }

    .boxPrestations{
      flex-direction: column;
      margin:0px;
      width:90%;
      text-align: center;
    }

    .carousel__item {
      width:100%;
      padding: 0;
    }

    .carousel__item_head{
      display:none
    }

    .carousel__item_body {
      border-radius: 14px;
      padding: 6em 20px 0px 20px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .TextPres {
      width: 100%;
      font-size:0.7em
    }

    .TitlePres {
      font-size:1em;
      border-radius: 15px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .wrapperPres{
      width:90%;
    }

    .demarche{
      flex-direction: column;
      align-items: center;
    }

    .demarchebox{
      width:90%;
      margin-top:5vh;
      margin-bottom: 5vh;
    }
    
    .demarchehead{
      width: 10vh;
      height: 10vh; 
      margin-top:-5vh;
    }

    .OurTeam{
      flex-direction: column;
    }

    .Teambox{
      width: 99%;
      margin-bottom: 4vh;
      position:relative
    }

    .Teambox::after {
      content: "";
      position: absolute; 
      bottom: 0; 
      left: 25%; 
      width: 50%;
      height: 4px; 
      background-color: var(--col1); 
      border-radius: 4px;
    }

    .TeamTitle {
      align-items: flex-end; /* Center items vertically */
    }
    .Teambox h1{
      font-size:1.8em
    }

    .TeamTitle h1, .TeamName h1{
      font-size: 1.7em;;
    }

    .TeamName{
      margin-bottom: 1em;;
    }
    .TeamPicture img{
      width: 40vw;
      height:40vw
    }

    .brandsCarousel {
        width: 85%;
    }
    
    .carouselBox {
        padding: 3%;
        padding-left: 5%;
        padding-right: 5%;
        height: 9em;
    }

    .imgbody img {
        width: 80%;
    }

}





  @media only screen   /* Smartphones (landscape) ----------- */
  and (min-width : 321px)
  and (max-device-width : 480px)
  and (orientation : landscape) {

    .TitlesWeb h1{
      font-size:2.3em;
    }
    .overlayText{
      top: 50%;
      left: 2%;
      right:2%;
    }
    .overlayText h1{
        max-width: 90%;
    }
    .overlayText h2{
      max-width: 90%;;
    }
    .overlayText h1 {
        font-size: 40px;
    }

    .overlayText h2 {
        font-size: 16px;
    }

    .overlayText .contactButton {
        padding: 8px 15px;
        font-size: 14px;
        border-radius:20px;
        border-top-left-radius:0px;
        border-bottom-right-radius:0px;
    }

    .overlayText .contactButton2 {
        padding: 8px 15px;
        font-size: 14px;
        border-radius:20px;
        border-top-left-radius:0px;
        border-bottom-right-radius:0px;
    }

    .backgroundImage {
      background-image: url('../../../public/backamje2.webp');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 0;
      padding-bottom: 25.05%; /* (img-height / img-width * container-width) */
      padding-top:25.05%

    }
    .contactButtonContainer{
      flex-direction:column;
    }

    .overlayText .contactButton img {
        width: 18px;
    }
    .overlayText .contactButton2 img {
        width: 18px;
    }
    .Figures{
      flex-direction: row;
    }
    .boxFigure{
      width:95%
    }

    .boxFigure h1{
      font-size:1.7em;
    }

    .boxFigure h2{
      font-size:1em;
    }

    .TextPres h1{
      font-size:1.7em;
    }

    .TextPres h2{
      font-size:1.2em;
    }

    .carousel__item_body {
      border-radius: 14px;
      padding: 6em 20px 0px 20px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .carousel__item{
      width:100%;
      padding: 10px
    }

    .TitlePres {
      display: flex;
      align-items: center;
      font-size: 1em;
      font-family: 'GS', sans-serif;
      color: white;
      background-color: var(--col1);
      border-radius: 15px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 0px;
      padding: 3px 1.1em;
      width: min-content;
    }

    .carousel__item_head{
      display:none;
    }

    .demarche{
      flex-wrap:wrap;
    }

    .demarchebox {
      width: 40%;
      margin-bottom:4em;
      transform:scale(0.95);
    }

    .opaque {
      opacity: 1 !important;
      transform: scale(1.05);
    }

    .demarchebox:hover{
      cursor:pointer;
      transform: scale(1.05);
    }


    .OurTeam{
      flex-direction: row;
      flex-wrap:wrap;
    }

    .Teambox{
      width: 35%;
      margin-bottom: 4vh;
      position:relative
    }

    .Teambox h1{
      font-size:1.3em
    }

    .TeamPicture img{
      width: 30vw;
      height:30vw
    }

  }




  
  @media only screen   /* Tablet (landscape) ----------- */
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : landscape) {
  }




  /* Tablet (portrait) ----------- */
  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    
    .overlayText{
      top: 50%;
      left: 2%;
      right:2%
    }
    .overlayText h1{
      max-width: 90%;
      font-size:2em;
    }
    .overlayText h2{
      max-width: 90%;;
    }
    .overlayText h1 {
      font-size: 40px;
    }

    .overlayText h2 {
      font-size: 16px;
    }

    .overlayText .contactButton {
      padding: 8px 15px;
      font-size: 14px;
      border-radius:20px;
      border-top-left-radius:0px;
      border-bottom-right-radius:0px;
    }

    .overlayText .contactButton2 {
      padding: 8px 15px;
      font-size: 14px;
      border-radius:20px;
      border-top-left-radius:0px;
      border-bottom-right-radius:0px;
    }

    .backgroundImage {
      background-image: url('../../../public/backamje.webp');
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 0;
      padding-bottom: 25.03%; /* (img-height / img-width * container-width) */
      padding-top:25.03% /* (961 / 1920 * 100) puis divisé par 2*/
    }

    .subtitlesContainer{
      width:90%
    }

    .subtitles h2{
      font-size:1.2em;
    }

    .Figures{
      flex-direction: row;
    }

    .boxFigure{
      width:80%
    }

    .boxFigure h1{
      font-size:1.6em;
    }

    .boxFigure h2{
      font-size:1em;
    }

    .TitlesWeb h1{
      font-size:2.8em;
    }

    .boxPrestations{
      flex-direction: column;
      margin:0px;
      width:90%;
      text-align: center;
    }

    .carousel__item {
      width:100%;
      padding: 0;
    }

    .carousel__item_head{
      display:none
    }

    .carousel__item_body {
      border-radius: 14px;
      padding: 12em 20px 0px 20px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .TextPres {
      width: 100%;
      font-size:0.7em
    }

    .TextPres h1{
      font-size:2.7em;
      margin-bottom:5%;
    }

    .wrapperPres{
      width:90%;
    }

    .demarche{
      flex-direction: column;
      align-items: center;
    }

    .demarchebox{
      width:90%;
      margin-top:5vh;
      margin-bottom: 5vh;
    }

    .demarchehead{
      width: 10vh;
      height: 10vh; 
      margin-top:-5vh;
    }

    .OurTeam{
      flex-direction: column;
    }

    .Teambox{
      width: 99%;
      margin-bottom: 4vh;
      position:relative
    }

    .Teambox::after {
      content: ""; 
      position: absolute; 
      bottom: 0;
      left: 25%; 
      width: 50%; 
      height: 4px;
      background-color: var(--col1); 
      border-radius: 4px; 
    }

    .Teambox h1{
      font-size:1.8em
    }

    .TeamPicture img{
      width: 40vw;
      height:40vw
    }

    .brandsCarousel {
        width: 85%;
    }

    .carouselBox {
        padding: 3%;
        padding-left: 5%;
        padding-right: 5%;
        height: 6em;
    }

    .imgbody img {
        width: 80%;
    }
  }







