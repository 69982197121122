:root {
  --col1: #EB5400;
  --col2: #322C5A;
}

@font-face {
  font-family: 'GB';
  src: url('./../../../public/Gilroy-Bold.woff') format('woff');
}

@font-face {
  font-family: 'GM';
  src: url('./../../../public/Gilroy-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GR';
  src: url('./../../../public/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GS';
  src: url('./../../../public/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.body {
  background-color: #f8f9fa;
  color: var(--col2);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
}

.formContainer {
  width: 100%;
  max-width: 50%;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 10%;
}

.formContainer h1 {
  font-family: 'GS', sans-serif;
  text-align: center;
  font-size: 2.8em;
  margin-bottom: 15px;
  color: var(--col2);
  text-decoration: underline !important;
  text-decoration-color: #EB5400 !important;
  text-underline-offset: 15px;
}

.formContainer h2 {
  font-family: 'GM', sans-serif;
  text-align: left;
  font-size: 20px;
  padding-top: 22px;
  margin-bottom: 22px;
  color: var(--col2);
}

.formContainer h2 a {
  color: #EB5400;
  cursor: pointer;
}

.formField input,
.formField textarea {
  width: 100%;
  padding: 9px;
  box-sizing: border-box;
  border: 2px solid #EB5400;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #fff;
  font-family: 'GR', sans-serif;
  font-size: 18px;
  color: var(--col2);
}

.formField button:hover {
  background-color: #322C5A;
}

.formField label {
  font-family: "GM";
  font-size: 19px;
  color: var(--col2);
}

.formField input {
  height: 38px;
}

.textareaField {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 2px solid #EB5400;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #ffffff;
  height: 60px;
}

.formRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.formField {
  width: 100%;
}

.formField + .formField {
  margin-left: 5%;
}

.formContainer .buttonContainer button {
  margin-top: 15px;
  border: none;
  background-color: var(--col1);
  color: white;
  font-family: 'GS', sans-serif;
  padding: 11px 10px;
  font-size: 20px;
  height: 100%;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 25px;
  transition: border-radius 0.5s ease;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0px;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.formContainer .buttonContainer button:hover {
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.confirmationMessage {
  font-family: 'GM';
  color: green;
  text-align: center;
  font-size: 19px;
}

/* For medium-sized screens (e.g., tablets) */
@media (max-width: 768px) {
  /* Vous pouvez ajouter des styles spécifiques aux tablettes ici */
}

@media only screen /* Smartphones (portrait) ----------- */
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (orientation: portrait) {

  .formContainer {
    max-width: 90%;
    padding: 10px;
    margin-top: 4em;
  }

  .formContainer h1 {
    font-size: 2em;
    text-underline-offset: 8px;
  }

  .formContainer h2 {
    font-size: 0.8em;
    padding-top: 15px;
    margin-bottom: 22px;
    color: var(--col2);
  }

  .formField label {
    font-family: "GM";
    font-size: 0.9em;
    color: var(--col2);
  }

  .formField input,
  .formField textarea {
    font-size: 0.8em;
    color: var(--col2);
  }

  .formField textarea {
    height: 15em;
  }

  .formField button {
    background-color: #EB5400;
    color: white;
    padding: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .formField button:hover {
    background-color: #322C5A;
  }

  .textareaField {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 2px solid #EB5400;
    border-radius: 4px;
    margin-bottom: 8px;
    background-color: #ffffff;
    height: 30px;
  }

  .formRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .formField {
    width: 100%;
  }

  .formField + .formField {
    margin-left: 5%;
  }

  .formContainer .buttonContainer button {
    padding: 8px 8px;
    font-size: 1.1em;
    cursor: pointer;
    width: 40%;
    border-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0px;
  }

  .formContainer .buttonContainer button:hover {
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .confirmationMessage {
    font-family: 'GM';
    color: green;
    text-align: center;
    font-size: 0.9em;
  }
}

@media only screen /* Smartphones (landscape) ----------- */
  and (min-width: 321px)
  and (max-device-width: 480px)
  and (orientation: landscape) {

  .formContainer {
    max-width: 90%;
    padding: 10px;
    margin-top: 4em;
  }

  .formContainer h1 {
    font-size: 2.5em;
    text-underline-offset: 8px;
  }

  .formContainer h2 {
    font-size: 1.1em;
  }
}
